import * as React from "react"

import { Input, InputProps } from "@supernovaio/dm/src/components/primitives"

import { normalizeTestId } from "../../utils/normalizeTestId"

export type DMTextInputProps = Pick<
  InputProps,
  | "placeholder"
  | "value"
  | "defaultValue"
  | "onChange"
  | "name"
  | "onBlur"
  | "onFocus"
  | "size"
  | "icon"
  | "variant"
  | "startSlot"
  | "hideOutline"
  | "endSlot"
  | "className"
  | "id"
  | "hasError"
  | "selectOnFocus"
  | "preventEnterSubmit"
  | "type"
  | "autoFocus"
  | "onKeyDown"
  | "onKeyUp"
  | "autoComplete"
  | "onPointerDown"
  | "style"
> & {
  isDisabled?: InputProps["disabled"]
  isReadOnly?: InputProps["readOnly"]
  containerClassname?: string
  disablesAutoComplete?: boolean
  dataTestId?: string
}

const DMTextInput = React.forwardRef<HTMLInputElement, DMTextInputProps>(
  (
    {
      isDisabled,
      isReadOnly,
      disablesAutoComplete = false,
      containerClassname,
      className,
      value,
      dataTestId,
      ...props
    },
    ref
  ) => {
    return (
      <Input
        ref={ref}
        autoComplete={disablesAutoComplete ? "off" : undefined}
        className={containerClassname}
        disabled={isDisabled}
        inputClassName={className}
        readOnly={isReadOnly}
        value={value}
        type={disablesAutoComplete ? "search" : "text"}
        data-test-id={normalizeTestId(dataTestId)}
        {...props}
      />
    )
  }
)

DMTextInput.displayName = "DMTextInput"

export { DMTextInput }
