import React from "react"

import { cn } from "@supernovaio/dm/src/utils/cn"

type DMFieldProps = Pick<
  React.HTMLAttributes<HTMLDivElement>,
  "className" | "children"
>

function DMField({ children, className }: DMFieldProps) {
  return <div className={cn("flex flex-col gap-4", className)}>{children}</div>
}

export default DMField
