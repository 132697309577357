"use client"

import React, { useCallback } from "react"

export type SelectOnFocusInputWrapperProps = {
  inputRefObject: React.RefObject<HTMLInputElement | HTMLTextAreaElement>
  selectOnFocus?: boolean | undefined
  render: ({ onFocus }: { onFocus: () => void }) => React.ReactElement
}

function SelectOnFocusInputWrapper({
  inputRefObject,
  selectOnFocus = false,
  render,
}: SelectOnFocusInputWrapperProps) {
  const onFocus = useCallback(() => {
    if (!selectOnFocus) {
      return
    }

    if (inputRefObject.current === document.activeElement) {
      inputRefObject.current?.select()
    }
  }, [inputRefObject, selectOnFocus])

  return render({ onFocus })
}

SelectOnFocusInputWrapper.displayName = "SelectOnFocusInputWrapper"

export default SelectOnFocusInputWrapper
