"use client"

import React, { useRef } from "react"

export type RefObjectExtractorProps<T extends HTMLElement> = {
  forwardedRef: React.ForwardedRef<T>
  render: ({
    refObject,
    actualForwardedRef,
  }: {
    refObject: React.RefObject<T>
    actualForwardedRef: React.ForwardedRef<T>
  }) => React.ReactElement
}

/** Component which provides a non-nullable `RefObject` instance.
 *
 * @param forwardedRef - A `forwardedRef` which is used to extract `refObject`.
 * If `null` is provided, `RefObjectExtractor` manages the `refObject` instance internally.
 *
 * @param render - A function which is called with `refObject` and `actualForwardedRef`.
 * `actualForwardedRef` should be used instead of `forwardedRef` in child components.
 */
function RefObjectExtractor<T extends HTMLElement>({
  forwardedRef,
  render,
}: RefObjectExtractorProps<T>) {
  const internalRef = useRef<T | null>(null)
  let actualForwardedRef: React.ForwardedRef<T>
  let refObject: React.RefObject<T>

  if (forwardedRef === null) {
    // No external ref is provided - use the internal one

    actualForwardedRef = internalRef
    refObject = internalRef
  } else if (forwardedRef != null && typeof forwardedRef === "function") {
    // An external ref is defined as a callback function - rewrap it to extract `element`

    actualForwardedRef = (element: T | null) => {
      forwardedRef(element)
      internalRef.current = element
    }

    refObject = internalRef
  } else {
    // An external forwardedRef is defined as an object - use it as is

    actualForwardedRef = forwardedRef
    refObject = forwardedRef
  }

  return render({ refObject, actualForwardedRef })
}

export default RefObjectExtractor
