import React, { useCallback } from "react"

import { DMIcon } from "@supernovaio/dm"

import { IconType } from "@supernovaio/icons/types"

import { FocusInputWrapper, FocusInputWrapperProps } from "../FocusInputWrapper"
import { InputBaseProps } from "../InputBase"
import RefObjectExtractor from "../RefObjectExtractor/RefObjectExtractor"
import SlottedInput from "../SlottedInput/SlottedInput"

export type InputProps = InputBaseProps & {
  icon?: IconType
  variant?: FocusInputWrapperProps["variant"]
  startSlot?: React.ReactNode
  endSlot?: React.ReactNode
  inputClassName?: string
  hideOutline?: boolean
  preventEnterSubmit?: boolean
  dataTestId?: string
} & Pick<FocusInputWrapperProps, "hasError" | "selectOnFocus">

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      icon,
      variant,
      disabled,
      readOnly,
      size = "medium",
      hasError,
      selectOnFocus = false,
      className,
      inputClassName,
      preventEnterSubmit,
      hideOutline = false,
      onKeyDown,
      dataTestId,
      ...props
    },
    ref
  ) => {
    const preventEnterSubmitIfNeeded = useCallback(
      (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter" && preventEnterSubmit) {
          event.currentTarget.blur()
          event.preventDefault()
        }
      },
      [preventEnterSubmit]
    )

    const handleOnKeyDown = useCallback(
      (event: React.KeyboardEvent<HTMLInputElement>) => {
        preventEnterSubmitIfNeeded(event)
        onKeyDown?.(event)
      },
      [preventEnterSubmitIfNeeded, onKeyDown]
    )

    return (
      <RefObjectExtractor<HTMLInputElement>
        forwardedRef={ref}
        render={({ refObject, actualForwardedRef }) => (
          <FocusInputWrapper
            className={className}
            disabled={disabled}
            hasError={hasError}
            hideOutline={hideOutline}
            inputRefObject={refObject}
            readOnly={readOnly}
            selectOnFocus={selectOnFocus}
            size={size}
            variant={variant}
          >
            {icon && (
              <DMIcon
                className={size === "large" ? "ml-12 -ml-4" : "ml-8"}
                color="neutral-faded"
                size="small"
                svg={icon}
              />
            )}
            <SlottedInput
              {...props}
              ref={actualForwardedRef}
              disabled={disabled}
              inputClassName={inputClassName}
              readOnly={readOnly}
              size={size}
              onKeyDown={handleOnKeyDown}
              dataTestId={dataTestId}
            />
          </FocusInputWrapper>
        )}
      />
    )
  }
)

Input.displayName = "Input"

export default Input
