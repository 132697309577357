import * as React from "react"

import { Slot } from "@supernovaio/dm"
import { cn } from "@supernovaio/dm/src/utils/cn"

import { InputBase, InputBaseProps } from "../InputBase"

export type SlottedInputProps = InputBaseProps & {
  startSlot?: React.ReactNode
  endSlot?: React.ReactNode
  inputClassName?: string
}

const SlottedInput = React.forwardRef<HTMLInputElement, SlottedInputProps>(
  (
    {
      endSlot,
      startSlot,
      className,
      disabled,
      readOnly,
      inputClassName,
      ...props
    },
    ref
  ) => {
    return (
      <div className={cn("flex w-full flex-row items-center", className)}>
        {startSlot && (
          <Slot onClick={(e) => e.stopPropagation()}>
            <div className="flex min-w-[20px] shrink-0 items-center self-stretch pl-8">
              {startSlot}
            </div>
          </Slot>
        )}
        <InputBase
          {...props}
          ref={ref}
          className={inputClassName}
          disabled={disabled}
          readOnly={readOnly}
        />
        {endSlot && (
          <Slot onClick={(e) => e.stopPropagation()}>
            {/** Using Slot here is an alternative to passing onClick to div and muting a11y warning. */}
            <div className="flex min-w-[20px] shrink-0 items-center self-stretch pr-8">
              {endSlot}
            </div>
          </Slot>
        )}
      </div>
    )
  }
)

SlottedInput.displayName = "SlottedInput"

export default SlottedInput
