import React from "react"

import { IconDelete } from "@supernovaio/icons"

import {
  DMButtonCritical,
  DMButtonNeutral,
  DMButtonPrimary,
  DMButtonUpgrade,
} from "../DMButtons"
import { DMField } from "../DMField"
import { DMLabel } from "../DMLabel"
import { DMTextInput } from "../DMTextInput"
import { DMTooltip } from "../DMTooltip"

import {
  DMDialog,
  DMDialogContentChildren,
  DMDialogContent,
  DMDialogDescription,
  DMDialogFooter,
  DMDialogFooterProps,
  DMDialogHeader,
  DMDialogProps,
  DMDialogTitle,
} from "./DMDialog"
import { DMDialogWell } from "./DMDialogWell"

export type DMConfirmationDialogProps = DMDialogProps & {
  buttonLabelCancel?: string
  buttonLabelConfirm: React.ReactNode
  isLoading?: boolean
  title: string
  description: React.ReactNode
  onConfirm: () => void
  onCancel?: () => void
  confirmationInputText?: string
  variant?: "neutral" | "critical" | "upgrade"
  hasIcon?: boolean
} & Pick<DMDialogFooterProps, "moreActionsSlot">

export function DMConfirmationDialog(props: DMConfirmationDialogProps) {
  const {
    title,
    description,
    children,
    isLoading,
    buttonLabelConfirm,
    buttonLabelCancel,
    variant = "neutral",
    onConfirm,
    onCancel,
    moreActionsSlot,
    open,
    confirmationInputText,
    hasIcon = true,
  } = props

  const [confirmationText, setConfirmationText] = React.useState("")

  const handleConfirm = () => {
    setConfirmationText("")
    onConfirm()
  }

  // We disable the confirm button if the confirmation text is required for continuing
  const isDisabled = confirmationInputText
    ? confirmationInputText.toUpperCase() !== confirmationText.toUpperCase()
    : false

  const renderConfirmationButton = () => {
    switch (variant) {
      case "critical":
        return (
          <DMButtonCritical
            icon={hasIcon ? IconDelete : undefined}
            isDisabled={isDisabled}
            isLoading={isLoading}
            onClick={handleConfirm}
          >
            {buttonLabelConfirm}
          </DMButtonCritical>
        )
      case "upgrade":
        return (
          <DMButtonUpgrade
            isDisabled={isDisabled}
            isLoading={isLoading}
            onClick={handleConfirm}
          >
            {buttonLabelConfirm}
          </DMButtonUpgrade>
        )
      default:
        return (
          <DMButtonPrimary
            isDisabled={isDisabled}
            isLoading={isLoading}
            onClick={handleConfirm}
          >
            {buttonLabelConfirm}
          </DMButtonPrimary>
        )
    }
  }

  return (
    <DMDialog open={open} onOpenChange={onCancel || handleConfirm}>
      <DMDialogContent>
        <DMDialogContentChildren>
          <DMDialogHeader>
            <DMDialogTitle>{title}</DMDialogTitle>
            <DMDialogDescription>{description}</DMDialogDescription>
          </DMDialogHeader>
          <div className="text-body text-neutral">{children}</div>
          {confirmationInputText && (
            // a11y todo: wrap it into form when there is extra confirmation (+ make "Enter" work for submit)
            <DMDialogWell variant={variant}>
              <DMField>
                <DMLabel htmlFor="extra-check">
                  Type{" "}
                  <span className="uppercase">{confirmationInputText}</span> to
                  confirm
                </DMLabel>
                <DMTextInput
                  className="font-semibold uppercase" // todo: extend DMTextInput to support emphasized text
                  id="extra-check"
                  name="extra-check"
                  placeholder={confirmationInputText}
                  onChange={(e) => setConfirmationText(e.target.value)}
                />
              </DMField>
            </DMDialogWell>
          )}
          <DMDialogFooter moreActionsSlot={moreActionsSlot}>
            {onCancel && (
              <DMButtonNeutral onClick={onCancel}>
                {buttonLabelCancel || "Cancel"}
              </DMButtonNeutral>
            )}
            <DMTooltip
              delayDuration={0}
              isDisabled={!isDisabled}
              text="Confirm the text above before continuing"
            >
              {renderConfirmationButton()}
            </DMTooltip>
          </DMDialogFooter>
        </DMDialogContentChildren>
      </DMDialogContent>
    </DMDialog>
  )
}
