import React from "react"

import { cn } from "@supernovaio/dm/src/utils/cn"

import { DMWell, DMWellProps } from "../DMWell"

export type DialogWellProps = Pick<
  DMWellProps,
  "variant" | "children" | "className"
> & {
  disableBleed?: boolean
}

export function DMDialogWell(props: DialogWellProps) {
  const { variant, children, className, disableBleed } = props

  return (
    <DMWell
      bleed={disableBleed ? 0 : 24}
      className={cn("text-body text-neutral", className)}
      variant={variant}
    >
      {children}
    </DMWell>
  )
}
