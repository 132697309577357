"use client"

import * as React from "react"

import { cn } from "@supernovaio/dm/src/utils/cn"

export type DMSegmentedInputWrapperProps = {
  children: React.ReactNode
  startAddon?: React.ReactNode | string
  endAddon?: React.ReactNode | string
}

export const SegmentedInputChildModifier =
  "group-data-[sn-end-addon=true]/segmentedInput:rounded-r-none group-data-[sn-start-addon=true]/segmentedInput:rounded-l-none"

export function DMSegmentedInputWrapper(props: DMSegmentedInputWrapperProps) {
  const { children, startAddon, endAddon } = props
  const isStartAddonString = typeof startAddon === "string"
  const isEndAddonString = typeof endAddon === "string"

  return (
    <div
      className={cn(
        "h-form-medium text-body group/segmentedInput flex",
        !startAddon && endAddon && "[&>*:first-child]:rounded-r-none",
        startAddon && !endAddon && "[&>*:nth-child(2)]:rounded-l-none",
        startAddon && endAddon && "[&>*:nth-child(2)]:rounded-none"
      )}
      data-sn-end-addon={!!endAddon}
      data-sn-start-addon={!!startAddon}
    >
      {startAddon && (
        <div className="min-w-form-medium bg-elevation-base inner-border inner-border-neutral-faded z-0 -mr-[1px] flex items-center justify-center rounded rounded-r-none p-[1px]">
          <div
            className={isStartAddonString ? "bg-neutral-faded h-full p-8" : ""}
          >
            {startAddon}
          </div>
        </div>
      )}
      {children}
      {endAddon && (
        <div className="min-w-form-medium bg-elevation-base inner-border inner-border-neutral-faded z-0 -ml-[1px] flex items-center justify-center rounded rounded-l-none p-[1px]">
          <div
            className={isEndAddonString ? "bg-neutral-faded h-full p-8" : ""}
          >
            {endAddon}
          </div>
        </div>
      )}
    </div>
  )
}

DMSegmentedInputWrapper.displayName = "DMSegmentedInputWrapper"
