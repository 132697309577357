import React, { InputHTMLAttributes } from "react"

import { cn } from "@supernovaio/dm/src/utils/cn"
import { normalizeTestId } from "@supernovaio/dm/src/utils/normalizeTestId"

import { cva, VariantProps } from "class-variance-authority"

const inputVariants = cva([""], {
  variants: {
    size: {
      small: "h-form-small text-body-small px-8",
      medium: "h-form-medium text-body px-8",
      large: "h-form-large text-body px-12",
    },
  },
})

export type InputBaseProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  "size"
> &
  VariantProps<typeof inputVariants> & {
    dataTestId?: string
  }

const InputBase = React.forwardRef<HTMLInputElement, InputBaseProps>(
  (
    {
      className,
      size,
      defaultValue,
      value,
      onChange,
      dataTestId,
      ...restProps
    },
    ref
  ) => {
    return (
      <input
        ref={ref}
        className={cn(
          inputVariants({ size }),
          "w-full bg-[transparent]",
          "disabled:cursor-not-allowed",
          "placeholder:text-neutral-placeholder",
          "focus:outline-none",
          className
        )}
        onClick={(e) => e.stopPropagation()}
        {...(defaultValue !== undefined && defaultValue !== null
          ? { defaultValue }
          : { value: value === null ? "" : value, onChange })}
        data-1p-ignore=""
        data-test-id={normalizeTestId(dataTestId)}
        {...restProps}
      />
    )
  }
)

InputBase.displayName = "Input"

export default InputBase
