"use client"

import * as React from "react"

import { cn } from "@supernovaio/dm/src/utils/cn"

import * as LabelPrimitive from "@radix-ui/react-label"

type DMLabelProps = React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root>

const DMLabel = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  DMLabelProps
>(({ className, ...props }, ref) => (
  <div className="flex max-w-full">
    <LabelPrimitive.Root
      ref={ref}
      className={cn("text-body font-semibold", className)}
      {...props}
    />
  </div>
))

DMLabel.displayName = LabelPrimitive.Root.displayName

export { DMLabel }
