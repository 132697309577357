import * as React from "react"
import { SVGProps } from "react"

export const IconDelete = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.1}
      d="M2.667 4.667h10.666M6.667 7.333v4m2.666-4v4m-6-6.666.667 8A1.333 1.333 0 0 0 5.333 14h5.334A1.334 1.334 0 0 0 12 12.667l.667-8M6 4.667v-2A.667.667 0 0 1 6.667 2h2.666a.667.667 0 0 1 .667.667v2"
    />
  </svg>
)
